<template>
  <zg-section
    width="xl"
    extra-y-padding
  >
    <h2 v-html="title" />

    <ul>
      <li
        v-for="(item, index) in items"
        :key="index"
      >
        <span>{{ index + 1 }}</span>

        <div>
          <h6>{{ item.title }}</h6>
          <p>{{ item.text }}</p>
        </div>
      </li>
    </ul>
  </zg-section>
</template>

<script setup lang="ts">
  import { defineAsyncComponent, type PropType } from 'vue'

  interface Items {
    title: string
    text: string
  }

  const ZgSection = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then(c => c.ZgSection))

  defineProps({
    title: {
      type: String as PropType<string>,
      required: false,
      default: null
    },
    items: {
      type: Array as PropType<Items[]>,
      required: false,
      default: null
    }
  })
</script>

<style scoped lang="scss">
  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    align-items: flex-start;
    justify-content: center;
    padding: 0;
    margin: 0 (- rhythm(small));

    @include mq(medium) {
      flex-direction: row;
    }
  }

  li {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    padding: rhythm(small);

    @include mq(medium) {
      flex-direction: column;
      align-items: center;
      width: calc(100% / 3);
    }
  }

  span {
    @include type-title-s;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    color: #fff;
    background-color: #24385b;
    border-radius: 100%;
    width: rem(60px);
    height: rem(60px);
    margin: 0 rhythm() 0 0;

    @include mq(medium) {
      margin: 0 0 rhythm();
    }
  }

  h2,
  h6 {
    @include mq(medium) {
      text-align: center;
    }
  }

  p {
    margin: 0;

    @include mq(medium) {
      text-align: center;
    }
  }
</style>
