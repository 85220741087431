export default {
  sv: {
    path: 'content.sections',
    replace: true,
    data: {
      contentId: '6dnMH43dzUz41zL3XlPoYf',
      componentRef: 'BrokerHero',
      title: 'Jämför & ansök om lån',
      subTitle: 'Hos Freedom Finance ansöker du om lån helt kostnadsfritt och utan bindning. Börja din resa mot ett bra lån här – din ansökan tar bara ett par minuter!',
      usps: [
        'Ansök på två minuter',
        'Låna upp till 600 000 kr',
        'Upp till 35 långivare – bara en UC'
      ],
      image: {
        assetId: '5u5jp17a1Hd6pm2UkiY8Tq',
        assetType: 'image/jpeg',
        title: 'start page broker',
        src: '//images.ctfassets.net/l0gi7dcsjajc/5u5jp17a1Hd6pm2UkiY8Tq/d1327feaa2f8d11fad990c68fa4687c8/man_with_computer-w1280.jpeg',
        alt: 'start page broker',
        sources: [
          {
            media: '(max-width: 991px)',
            srcsets: [
              '//images.ctfassets.net/l0gi7dcsjajc/5u5jp17a1Hd6pm2UkiY8Tq/d1327feaa2f8d11fad990c68fa4687c8/man_with_computer-w1280.jpeg?w=1125&h=753&q=70&fm=webp&fit=fill 3x',
              '//images.ctfassets.net/l0gi7dcsjajc/5u5jp17a1Hd6pm2UkiY8Tq/d1327feaa2f8d11fad990c68fa4687c8/man_with_computer-w1280.jpeg?w=750&h=502&q=80&fm=webp&fit=fill 2x',
              '//images.ctfassets.net/l0gi7dcsjajc/5u5jp17a1Hd6pm2UkiY8Tq/d1327feaa2f8d11fad990c68fa4687c8/man_with_computer-w1280.jpeg?w=375&h=251&q=90&fm=webp&fit=fill 1x'
            ],
            type: 'image/webp'
          },
          {
            media: '(min-width: 992px)',
            srcsets: [
              '//images.ctfassets.net/l0gi7dcsjajc/5u5jp17a1Hd6pm2UkiY8Tq/d1327feaa2f8d11fad990c68fa4687c8/man_with_computer-w1280.jpeg?w=5760&h=3843&q=70&fm=webp&fit=fill 3x',
              '//images.ctfassets.net/l0gi7dcsjajc/5u5jp17a1Hd6pm2UkiY8Tq/d1327feaa2f8d11fad990c68fa4687c8/man_with_computer-w1280.jpeg?w=3840&h=2562&q=80&fm=webp&fit=fill 2x',
              '//images.ctfassets.net/l0gi7dcsjajc/5u5jp17a1Hd6pm2UkiY8Tq/d1327feaa2f8d11fad990c68fa4687c8/man_with_computer-w1280.jpeg?w=1920&h=1281&q=90&fm=webp&fit=fill 1x'
            ],
            type: 'image/webp'
          },
          {
            media: '(max-width: 991px)',
            srcsets: [
              '//images.ctfassets.net/l0gi7dcsjajc/5u5jp17a1Hd6pm2UkiY8Tq/d1327feaa2f8d11fad990c68fa4687c8/man_with_computer-w1280.jpeg?w=1125&h=753&q=70&fm=jpeg&fit=fill 3x',
              '//images.ctfassets.net/l0gi7dcsjajc/5u5jp17a1Hd6pm2UkiY8Tq/d1327feaa2f8d11fad990c68fa4687c8/man_with_computer-w1280.jpeg?w=750&h=502&q=80&fm=jpeg&fit=fill 2x',
              '//images.ctfassets.net/l0gi7dcsjajc/5u5jp17a1Hd6pm2UkiY8Tq/d1327feaa2f8d11fad990c68fa4687c8/man_with_computer-w1280.jpeg?w=375&h=251&q=90&fm=jpeg&fit=fill 1x'
            ],
            type: 'image/jpeg'
          },
          {
            media: '(min-width: 992px)',
            srcsets: [
              '//images.ctfassets.net/l0gi7dcsjajc/5u5jp17a1Hd6pm2UkiY8Tq/d1327feaa2f8d11fad990c68fa4687c8/man_with_computer-w1280.jpeg?w=5760&h=3843&q=70&fm=jpeg&fit=fill 3x',
              '//images.ctfassets.net/l0gi7dcsjajc/5u5jp17a1Hd6pm2UkiY8Tq/d1327feaa2f8d11fad990c68fa4687c8/man_with_computer-w1280.jpeg?w=3840&h=2562&q=80&fm=jpeg&fit=fill 2x',
              '//images.ctfassets.net/l0gi7dcsjajc/5u5jp17a1Hd6pm2UkiY8Tq/d1327feaa2f8d11fad990c68fa4687c8/man_with_computer-w1280.jpeg?w=1920&h=1281&q=90&fm=jpeg&fit=fill 1x'
            ],
            type: 'image/jpeg'
          }
        ]
      }
    }
  },
  nb: {
    path: 'content.sections',
    replace: true,
    data: {
      contentId: '6dnMH43dzUz41zL3XlPoYf',
      componentRef: 'BrokerHero',
      title: 'Beregn din månedskostnad',
      image: {
        assetId: '5u5jp17a1Hd6pm2UkiY8Tq',
        assetType: 'image/jpeg',
        title: 'start page broker',
        src: '//images.ctfassets.net/l0gi7dcsjajc/4x1v45Di2fcNpe8lwWssEe/11921ddc0177a43d4a01b5956c9b5df7/4358a0c24e708508ac1cb913b4a8bdf4.jpeg',
        alt: 'start page broker',
        sources: [
          {
            media: '(max-width: 991px)',
            srcsets: [
              '//images.ctfassets.net/l0gi7dcsjajc/4x1v45Di2fcNpe8lwWssEe/11921ddc0177a43d4a01b5956c9b5df7/4358a0c24e708508ac1cb913b4a8bdf4.jpeg?w=1125&h=753&q=70&fm=webp&fit=fill 3x',
              '//images.ctfassets.net/l0gi7dcsjajc/4x1v45Di2fcNpe8lwWssEe/11921ddc0177a43d4a01b5956c9b5df7/4358a0c24e708508ac1cb913b4a8bdf4.jpeg?w=750&h=502&q=80&fm=webp&fit=fill 2x',
              '//images.ctfassets.net/l0gi7dcsjajc/4x1v45Di2fcNpe8lwWssEe/11921ddc0177a43d4a01b5956c9b5df7/4358a0c24e708508ac1cb913b4a8bdf4.jpeg?w=375&h=251&q=90&fm=webp&fit=fill 1x'
            ],
            type: 'image/webp'
          },
          {
            media: '(min-width: 992px)',
            srcsets: [
              '//images.ctfassets.net/l0gi7dcsjajc/4x1v45Di2fcNpe8lwWssEe/11921ddc0177a43d4a01b5956c9b5df7/4358a0c24e708508ac1cb913b4a8bdf4.jpeg?w=5760&h=3843&q=70&fm=webp&fit=fill 3x',
              '//images.ctfassets.net/l0gi7dcsjajc/4x1v45Di2fcNpe8lwWssEe/11921ddc0177a43d4a01b5956c9b5df7/4358a0c24e708508ac1cb913b4a8bdf4.jpeg?w=3840&h=2562&q=80&fm=webp&fit=fill 2x',
              '//images.ctfassets.net/l0gi7dcsjajc/4x1v45Di2fcNpe8lwWssEe/11921ddc0177a43d4a01b5956c9b5df7/4358a0c24e708508ac1cb913b4a8bdf4.jpeg?w=1920&h=1281&q=90&fm=webp&fit=fill 1x'
            ],
            type: 'image/webp'
          },
          {
            media: '(max-width: 991px)',
            srcsets: [
              '//images.ctfassets.net/l0gi7dcsjajc/4x1v45Di2fcNpe8lwWssEe/11921ddc0177a43d4a01b5956c9b5df7/4358a0c24e708508ac1cb913b4a8bdf4.jpeg?w=1125&h=753&q=70&fm=jpeg&fit=fill 3x',
              '//images.ctfassets.net/l0gi7dcsjajc/4x1v45Di2fcNpe8lwWssEe/11921ddc0177a43d4a01b5956c9b5df7/4358a0c24e708508ac1cb913b4a8bdf4.jpeg?w=750&h=502&q=80&fm=jpeg&fit=fill 2x',
              '//images.ctfassets.net/l0gi7dcsjajc/4x1v45Di2fcNpe8lwWssEe/11921ddc0177a43d4a01b5956c9b5df7/4358a0c24e708508ac1cb913b4a8bdf4.jpeg?w=375&h=251&q=90&fm=jpeg&fit=fill 1x'
            ],
            type: 'image/jpeg'
          },
          {
            media: '(min-width: 992px)',
            srcsets: [
              '//images.ctfassets.net/l0gi7dcsjajc/4x1v45Di2fcNpe8lwWssEe/11921ddc0177a43d4a01b5956c9b5df7/4358a0c24e708508ac1cb913b4a8bdf4.jpeg?w=5760&h=3843&q=70&fm=jpeg&fit=fill 3x',
              '//images.ctfassets.net/l0gi7dcsjajc/4x1v45Di2fcNpe8lwWssEe/11921ddc0177a43d4a01b5956c9b5df7/4358a0c24e708508ac1cb913b4a8bdf4.jpeg?w=3840&h=2562&q=80&fm=jpeg&fit=fill 2x',
              '//images.ctfassets.net/l0gi7dcsjajc/4x1v45Di2fcNpe8lwWssEe/11921ddc0177a43d4a01b5956c9b5df7/4358a0c24e708508ac1cb913b4a8bdf4.jpeg?w=1920&h=1281&q=90&fm=jpeg&fit=fill 1x'
            ],
            type: 'image/jpeg'
          }
        ]
      }
    }
  },
  fi: {
    path: 'content.sections',
    merge: true,
    data: {
      contentId: '6dnMH43dzUz41zL3XlPoYf',
      componentRef: 'BrokerHero',
      title: 'Kilpailuta laina maksutta',
      image: {
        assetId: '5u5jp17a1Hd6pm2UkiY8Tq',
        assetType: 'image/jpeg',
        title: 'start page broker',
        src: '//images.ctfassets.net/l0gi7dcsjajc/4x1v45Di2fcNpe8lwWssEe/11921ddc0177a43d4a01b5956c9b5df7/4358a0c24e708508ac1cb913b4a8bdf4.jpeg',
        alt: 'start page broker',
        sources: [
          {
            media: '(max-width: 991px)',
            srcsets: [
              '//images.ctfassets.net/l0gi7dcsjajc/4x1v45Di2fcNpe8lwWssEe/11921ddc0177a43d4a01b5956c9b5df7/4358a0c24e708508ac1cb913b4a8bdf4.jpeg?w=1125&h=753&q=70&fm=webp&fit=fill 3x',
              '//images.ctfassets.net/l0gi7dcsjajc/4x1v45Di2fcNpe8lwWssEe/11921ddc0177a43d4a01b5956c9b5df7/4358a0c24e708508ac1cb913b4a8bdf4.jpeg?w=750&h=502&q=80&fm=webp&fit=fill 2x',
              '//images.ctfassets.net/l0gi7dcsjajc/4x1v45Di2fcNpe8lwWssEe/11921ddc0177a43d4a01b5956c9b5df7/4358a0c24e708508ac1cb913b4a8bdf4.jpeg?w=375&h=251&q=90&fm=webp&fit=fill 1x'
            ],
            type: 'image/webp'
          },
          {
            media: '(min-width: 992px)',
            srcsets: [
              '//images.ctfassets.net/l0gi7dcsjajc/4x1v45Di2fcNpe8lwWssEe/11921ddc0177a43d4a01b5956c9b5df7/4358a0c24e708508ac1cb913b4a8bdf4.jpeg?w=5760&h=3843&q=70&fm=webp&fit=fill 3x',
              '//images.ctfassets.net/l0gi7dcsjajc/4x1v45Di2fcNpe8lwWssEe/11921ddc0177a43d4a01b5956c9b5df7/4358a0c24e708508ac1cb913b4a8bdf4.jpeg?w=3840&h=2562&q=80&fm=webp&fit=fill 2x',
              '//images.ctfassets.net/l0gi7dcsjajc/4x1v45Di2fcNpe8lwWssEe/11921ddc0177a43d4a01b5956c9b5df7/4358a0c24e708508ac1cb913b4a8bdf4.jpeg?w=1920&h=1281&q=90&fm=webp&fit=fill 1x'
            ],
            type: 'image/webp'
          },
          {
            media: '(max-width: 991px)',
            srcsets: [
              '//images.ctfassets.net/l0gi7dcsjajc/4x1v45Di2fcNpe8lwWssEe/11921ddc0177a43d4a01b5956c9b5df7/4358a0c24e708508ac1cb913b4a8bdf4.jpeg?w=1125&h=753&q=70&fm=jpeg&fit=fill 3x',
              '//images.ctfassets.net/l0gi7dcsjajc/4x1v45Di2fcNpe8lwWssEe/11921ddc0177a43d4a01b5956c9b5df7/4358a0c24e708508ac1cb913b4a8bdf4.jpeg?w=750&h=502&q=80&fm=jpeg&fit=fill 2x',
              '//images.ctfassets.net/l0gi7dcsjajc/4x1v45Di2fcNpe8lwWssEe/11921ddc0177a43d4a01b5956c9b5df7/4358a0c24e708508ac1cb913b4a8bdf4.jpeg?w=375&h=251&q=90&fm=jpeg&fit=fill 1x'
            ],
            type: 'image/jpeg'
          },
          {
            media: '(min-width: 992px)',
            srcsets: [
              '//images.ctfassets.net/l0gi7dcsjajc/4x1v45Di2fcNpe8lwWssEe/11921ddc0177a43d4a01b5956c9b5df7/4358a0c24e708508ac1cb913b4a8bdf4.jpeg?w=5760&h=3843&q=70&fm=jpeg&fit=fill 3x',
              '//images.ctfassets.net/l0gi7dcsjajc/4x1v45Di2fcNpe8lwWssEe/11921ddc0177a43d4a01b5956c9b5df7/4358a0c24e708508ac1cb913b4a8bdf4.jpeg?w=3840&h=2562&q=80&fm=jpeg&fit=fill 2x',
              '//images.ctfassets.net/l0gi7dcsjajc/4x1v45Di2fcNpe8lwWssEe/11921ddc0177a43d4a01b5956c9b5df7/4358a0c24e708508ac1cb913b4a8bdf4.jpeg?w=1920&h=1281&q=90&fm=jpeg&fit=fill 1x'
            ],
            type: 'image/jpeg'
          }
        ]
      }
    }
  }
}
