<template>
  <div
    :class="['app-pages', {
      'app-pages--loaded': loaded,
      [`app-pages--${ctx?.brand}`]: ctx?.brand
    }]"
  >
    <div
      v-if="!isCustomClpPage"
      class="noprint"
    >
      <fragment
        :headers="headerFragment.headers as Record<string, string>"
        :timeout="10000"
        :url="headerFragment.url"
        render-mode="server"
      />
    </div>

    <main
      ref="main"
      :class="{ 'custom-clp-page': isCustomClpPage }"
    >
      <slot />
    </main>

    <div class="noprint">
      <fragment
        :headers="footerFragment.headers as Record<string, string>"
        :timeout="10000"
        :url="footerFragment.url"
        render-mode="server"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { getServerState, useSSRContext } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'
  import { computed, defineAsyncComponent, onBeforeUnmount, onMounted, ref } from 'vue'
  import { getFragment } from '../../composables/get-fragment'
  import { setCookie } from '../../utils/cookies/set-cookie'
  import { gclidDebug } from '../../utils/gclid-debug'

  const Fragment = defineAsyncComponent(async () => await import('@ocp-zmarta/zsc-plugin-vite-ssr/vue').then(m => m.Fragment))

  const ctx = import.meta.env.SSR
    ? useSSRContext()
    : ({
      brand: getServerState('brand'),
      market: getServerState('market'),
      locale: getServerState('locale'),
      vertical: getServerState('vertical'),
      userId: getServerState('userId'),
      userIp: getServerState('userIp'),
      features: getServerState('features'),
      path: getServerState('path')
    }) as unknown as NonNullable<ReturnType<typeof useSSRContext>>

  const loaded = ref(true)
  const showFooter = ref(false)
  const hero = ref<HTMLElement | null>(null)
  const heroObserver = ref<IntersectionObserver | null>(null)

  const showTranslations = computed(() => getServerState('vertical')?.toString() === 'cl')
  const showStickyCta = computed(() => getServerState('vertical')?.toString() === 'cl')

  const headerFragment = computed(() => {
    return getFragment({
      name: 'siteNavigation',
      path: '/header',
      query: { show_translations: showTranslations.value },
      ctx
    })
  })

  const footerFragment = computed(() => {
    return getFragment({
      name: 'siteNavigation',
      path: isCustomClpPage.value
        ? '/footer?minimal=true'
        : '/footer',
      query: { sticky_cta_spacing: !isCustomClpPage.value && showStickyCta.value },
      ctx
    })
  })

  const isCustomClpPage = computed(() => {
    return ['/clp/lan/custom', '/clp/forsakring/custom'].some(path => ctx?.path?.startsWith(path))
  })

  function setupHeroObserver () {
    if (!('IntersectionObserver' in window)) return

    hero.value = document.querySelector('.zg-hero')

    if (!hero.value) {
      showFooter.value = true
      return
    }

    heroObserver.value = new IntersectionObserver(([entry]) => {
      if (!showFooter.value && !entry.isIntersecting) {
        showFooter.value = true
      }
    })

    heroObserver.value?.observe(hero.value)
  }

  onMounted(() => {
    setCookie({ name: 'locale', value: ctx?.locale })
    setCookie({ name: 'op_user_id', value: ctx?.userId, type: 'op_user_id' })
    setupHeroObserver()
    void gclidDebug()
  })

  onBeforeUnmount(() => {
    heroObserver.value?.disconnect()
  })
</script>

<style lang="scss" scoped>
  main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 3.5rem;
  }

  .custom-clp-page {
    padding-top: 0;
  }

  @media print {
    .noprint {
      display: none;
    }

    main {
      padding-top: 0;
    }
  }

  // For some reason the background-color is not set on this class??
  :deep(.zg-section-bg-color--beige) {
    background-color: var(--beige);
  }

  .app-pages--broker {
    & > main {
      padding-top: rem(72px);

      @include mq(medium) {
        padding-top: rem(80px);
      }
    }

    :deep(.zg-hero) {
      display: none !important; /* stylelint-disable-line */

      h1 {
        font-size: rem(56px) !important; /* stylelint-disable-line */
        line-height: rem(64px) !important; /* stylelint-disable-line */
      }
    }

    :deep(.zg-text-section) {
      border-bottom: 1px solid #f2f2f2;

      aside {
        height: 100%;

        @include mq(medium) {
          border-left: 1px solid #f2f2f2;
          padding-left: rem(24px);
        }
      }

      & > div:first-child {
        img {
          margin: 0;
        }
      }

      a {
        color: #da8b2d;
        border-color: #da8b2d;
        font-weight: font-weight(bold);

        &:hover {
          color: #da8b2d;
          border-color: #da8b2d;
        }
      }
    }

    :deep(.zg-usps) {
      svg {
        fill: #da8b2d !important; /* stylelint-disable-line */
      }
    }

    :deep(.zg-button) {
      color: #000 !important; /* stylelint-disable-line */
      background-color: #1db5e4 !important; /* stylelint-disable-line */
      border-color: #1db5e4 !important; /* stylelint-disable-line */

      &:hover {
        background-color: #1799c1 !important; /* stylelint-disable-line */
        border-color: #1799c1 !important; /* stylelint-disable-line */
      }

      &.zg-button--outline {
        background-color: transparent !important; /* stylelint-disable-line */

        &:hover {
          background-color: transparent !important; /* stylelint-disable-line */
        }
      }

      &.zg-button--link {
        background-color: transparent !important; /* stylelint-disable-line */
        border-color: transparent !important; /* stylelint-disable-line */

        &:hover {
          color: #24385b !important; /* stylelint-disable-line */
        }
      }

      &.zg-button--ghost {
        background-color: transparent !important; /* stylelint-disable-line */
        border-color: transparent !important; /* stylelint-disable-line */
      }
    }

    :deep(.zg-section--background-beige) {
      background-color: #f2f2f2 !important; /* stylelint-disable-line */
    }

    :deep(.zg-testimonial-section) {
      .zg-section {
        background-color: #f2f2f2 !important; /* stylelint-disable-line */
      }

      .zg-testimonial {
        background-color: #fff !important; /* stylelint-disable-line */

        .zg-avatar {
          color: #000 !important; /* stylelint-disable-line */
          background-color: #f2f2f2 !important; /* stylelint-disable-line */
        }
      }

      .link {
        text-decoration: none !important; /* stylelint-disable-line */
      }
    }

    :deep(.zg-faq) {
      .item {
        border-bottom: 1px solid #ccc;
      }
    }
  }
</style>
