export default {
  sv: {
    path: 'content.sections',
    merge: true,
    data: {
      contentId: '11hlbF79slkoDNFv2FuyaZ',
      componentRef: 'BrokerCrossSell'
    }
  }
}
