export default {
  sv: {
    path: 'content.sections',
    merge: true,
    data: {
      contentId: '6v9zsbGdbcRsgS557MZnbV',
      componentRef: 'BrokerFaq',
      contact: {
        title: 'Kontakta kundservice',
        phone: {
          label: 'Tlf:',
          value: '0431-47 47 00'
        },
        email: {
          label: 'E-post:',
          value: 'info@freedomfinance.se'
        },
        hours: {
          label: 'Öppettider:',
          value: 'Alla dagar dygnet runt'
        },
        postal: {
          label: 'Postadress:',
          value: 'Zmarta AB<br>Box 1068<br>262 21 Ängelholm'
        }
      }
    }
  },
  nb: {
    path: 'content.sections',
    merge: true,
    data: {
      contentId: '4I2to1qIuumklkSSXhMLa',
      componentRef: 'BrokerFaq',
      contact: {
        title: 'Kontakt kundeservice',
        phone: {
          label: 'Tlf:',
          value: '23 96 70 10'
        },
        email: {
          label: 'E-post:',
          value: 'info@zmarta.no'
        },
        hours: {
          label: 'Åpningstider:',
          value: 'Mandag-fredag: kl. 8-17<br>Lørdag: Lukket<br>Søndag: Lukket'
        },
        postal: {
          label: 'Postadresse:',
          value: 'Zmarta AS<br>c/o Bauer Media AS<br>Postboks 1102 Sentrum<br>0104 Oslo'
        }
      }
    }
  },
  fi: {
    path: 'content.sections',
    merge: true,
    data: {
      contentId: '4I2to1qIuumklkSSXhMLa',
      componentRef: 'BrokerFaq',
      contact: {
        title: 'Kontakt kundeservice',
        phone: {
          label: 'Tlf:',
          value: '23 96 70 00'
        },
        email: {
          label: 'E-post:',
          value: 'info@centum.no'
        },
        hours: {
          label: 'Åpningstider:',
          value: 'Mandag-fredag: kl. 8-17<br>Lørdag: Lukket<br>Søndag: Lukket'
        },
        postal: {
          label: 'Postadresse:',
          value: 'Zmarta AS<br>c/o Bauer Media AS<br>Postboks 1102 Sentrum<br>0104 Oslo'
        }
      }
    }
  }
}
