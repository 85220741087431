<template>
  <zg-section
    width="xl"
    extra-y-padding
    class="section"
  >
    <div class="partners">
      <div class="header">
        <rich-text :body="subTitle" />
      </div>

      <div class="items">
        <zg-logo-card
          v-for="(item, index) in items"
          :key="index"
          v-bind="item"
          small
        />
      </div>
    </div>
  </zg-section>
</template>

<script setup lang="ts">
  import { RichText, type RichTextBody } from '@ocp-zmarta/content-sdk'
  import { defineAsyncComponent, type PropType } from 'vue'

  const ZgSection = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then(c => c.ZgSection))
  const ZgLogoCard = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then(c => c.ZgLogoCard))

  defineProps({
    subTitle: {
      type: Array as PropType<RichTextBody[]>,
      required: false,
      default: null
    },
    items: {
      type: Array as PropType<object[]>,
      required: false,
      default: () => []
    }
  })
</script>

<style scoped lang="scss">
  .section {
    border-top: 1px solid #f2f2f2;
  }

  .header {
    text-align: center;

    :deep(a) {
          color: #da8b2d;
      border-color: #da8b2d;
      font-weight: font-weight(bold);

      &:hover {
        color: #da8b2d;
        border-color: #da8b2d;
      }
    }
  }

  .items {
    display: flex;
    flex-flow: row wrap;
    gap: rhythm(small);

    @include mq(medium) {
      gap: rhythm();
    }

    & > a,
    & > div {
      flex-basis: calc(100% / 3 - (#{rhythm(small)} * 2 / 3));
      padding: 0;

      @include mq(medium) {
        flex-basis: calc(100% / 5 - (24px * 4 / 5)) !important; /* stylelint-disable-line */
        min-width: 0;
      }

      @include mq(large) {
        flex-basis: calc(100% / 7 - (24px * 6 / 7)) !important; /* stylelint-disable-line */
      }
    }
  }
</style>
