<template>
  <zg-section
    background="white"
    width="xl"
    extra-y-padding
  >
    <div
      :class="['faq', {
        'faq--with-sidebar': sidebar
      }]"
    >
      <div class="questions">
        <h3>{{ title }}</h3>
        <zg-faq :faqs="faqs" />
      </div>

      <faq-sidebar v-if="sidebar">
        <faq-contact :content="contact" />
      </faq-sidebar>
    </div>
  </zg-section>
</template>

<script setup lang="ts">
  import { computed, defineAsyncComponent } from 'vue'

  const FaqSidebar = defineAsyncComponent(async () => await import('./faq-sidebar.vue'))
  const FaqContact = defineAsyncComponent(async () => await import('./faq-contact.vue'))
  const ZgFaq = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then(c => c.ZgFaq))
  const ZgSection = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then(c => c.ZgSection))

  const props = defineProps({
    title: {
      type: String,
      required: false,
      default: null
    },
    body: {
      type: Array,
      required: false,
      default: null
    },
    sidebar: {
      type: Boolean,
      required: false,
      default: true
    },
    contact: {
      type: Object,
      required: false,
      default: () => {}
    }
  })

  const faqs = computed(() => {
    return props.body?.[0]?.value?.faqs
  })
</script>

<style scoped lang="scss">
  .faq {
    display: flex;
    flex-direction: column;

    @include mq(medium) {
      flex-direction: row;
    }

    & > div:first-child {
      flex-grow: 1;
    }

    :deep(a) {
      color: #24385b;
      border-color: #24385b;
      font-weight: bold;
    }
  }
</style>
