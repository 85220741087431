<template>
  <div class="hero">
    <div class="content">
      <hero-background
        v-if="image"
        :image="image"
      />

      <hero-cta-variation
        v-if="showHeroCtaVariation"
        :title="title"
        :sub-title="subTitle"
        :usps="usps"
        :trustpilot-data="trustpilotData"
      />

      <hero-cta
        v-else
        :title="title"
        :sub-title="subTitle"
        :usps="usps"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { getServerState, useSSRContext } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'
  import { computed, defineAsyncComponent, type PropType } from 'vue'

  const HeroBackground = defineAsyncComponent(async () => await import('./hero-background.vue'))
  const HeroCta = defineAsyncComponent(async () => await import('./hero-cta.vue'))
  const HeroCtaVariation = defineAsyncComponent(async () => await import('./hero-cta-variation.vue'))

  const ctx = import.meta.env.SSR
    ? useSSRContext()
    : ({
      brand: getServerState('brand'),
      market: getServerState('market'),
      locale: getServerState('locale'),
      device: getServerState('device'),
      userId: getServerState('userId'),
      userIp: getServerState('userIp')
    }) as unknown as NonNullable<ReturnType<typeof useSSRContext>>

  defineProps({
    title: {
      type: String as PropType<string>,
      required: false,
      default: null
    },
    subTitle: {
      type: String as PropType<string>,
      required: false,
      default: null
    },
    usps: {
      type: Array as PropType<object[]>,
      required: false,
      default: null
    },
    image: {
      type: Object as PropType<object>,
      required: false,
      default: () => {}
    },
    trustpilotData: {
      type: Object as PropType<object>,
      required: false,
      default: () => {}
    }
  })

  const showHeroCtaVariation = computed(() => {
    return ctx?.market === 'se'
  })
</script>

<style scoped lang="scss">
  .hero {
    .content {
      position: relative;
      background-color: #f2f2f2;
    }
  }
</style>
