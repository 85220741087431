<template>
  <zg-section
    width="xl"
    class="hero-cta"
    no-y-padding
  >
    <div
      :class="['box', {
        'box--right': ctx?.market === 'no',
        'box--min-height-no': ctx?.market === 'no',
        'box--min-height-fi': ctx?.market === 'fi'
      }]"
    >
      <h2 v-html="highlightedTitle" />
      <p>{{ subTitle }}</p>

      <fragment
        :headers="formFragment.headers as Record<string, string>"
        :timeout="10000"
        :url="formFragment.url"
        render-mode="server"
      />
    </div>
  </zg-section>
</template>

<script setup lang="ts">
  import { getServerState, useSSRContext } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'
  import { computed, defineAsyncComponent } from 'vue'
  import { getFragment } from '../../../../composables/get-fragment'
  import { highlightTitle } from '../../../../utils/higlight-title'

  const Fragment = defineAsyncComponent(async () => await import('@ocp-zmarta/zsc-plugin-vite-ssr/vue').then(m => m.Fragment))
  const ZgSection = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then(c => c.ZgSection))

  const ctx = import.meta.env.SSR
    ? useSSRContext()
    : ({
      brand: getServerState('brand'),
      market: getServerState('market'),
      locale: getServerState('locale'),
      device: getServerState('device'),
      userId: getServerState('userId'),
      userIp: getServerState('userIp')
    }) as unknown as NonNullable<ReturnType<typeof useSSRContext>>

  const props = defineProps({
    title: {
      type: String,
      required: false,
      default: null
    },
    subTitle: {
      type: String,
      required: false,
      default: null
    }
  })

  const highlightedTitle = computed(() => {
    return highlightTitle(ctx?.market as string, props.title)
  })

  const formFragment = computed(() => {
    const path = ctx?.market === 'fi'
      ? '/cl-form/single-step'
      : '/cl-form/calculator'

    return getFragment({
      name: 'clForm',
      path,
      query: {
        disclaimer: true,
        locale: ctx?.locale,
        op_user_id: ctx?.userId
      },
      ctx
    })
  })
</script>

<style lang="scss" scoped>
  .hero-cta {
    position: relative;
    z-index: 2;
    margin: 0;
    padding: 0;
    background-color: transparent !important; /* stylelint-disable-line */

    @include mq(medium) {
      margin: 0;
      padding: rhythm(6) 0 !important; /* stylelint-disable-line */
    }
  }

  .box {
    background-color: #fff;
    padding: rhythm(small);
    margin: 0 (- rhythm(small));

    @include mq(medium) {
      border-radius: radius(box);
      box-shadow: box-shadow();
      padding: rhythm();
      margin: 0;
    }

    @include mq(large) {
      width: rhythm(76);
    }

    &--right {
      @include mq(medium) {
        margin-left: auto;
      }
    }

    &--min-height-no {
      @include mq(medium) {
        min-height: rem(614px);
      }
    }

    &--min-height-fi {
      @include mq(medium) {
        min-height: rem(672px);
      }
    }
  }

  h2 {
    :deep(span) {
      color: #da8b2d;
    }
  }
</style>
