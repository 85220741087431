<template>
  <div
    ref="background"
    class="background"
    :style="{ height: height }"
  >
    <zg-image
      :src="image.src"
      :sources="image.sources"
      :class="{ 'mirror': mirrorImage }"
    />
  </div>
</template>

<script setup lang="ts">
  import { getServerState, useSSRContext } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'
  import { computed, defineAsyncComponent, onMounted, ref } from 'vue'

  const ZgImage = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then(c => c.ZgImage))

  const ctx = import.meta.env.SSR
    ? useSSRContext()
    : ({
      brand: getServerState('brand'),
      market: getServerState('market'),
      locale: getServerState('locale'),
      device: getServerState('device'),
      userId: getServerState('userId'),
      userIp: getServerState('userIp')
    }) as unknown as NonNullable<ReturnType<typeof useSSRContext>>

  defineProps({
    image: {
      type: Object,
      required: false,
      default: () => {}
    }
  })

  const background = ref<HTMLElement | null>(null)
  const height = ref('')
  const mirrorImage = computed(() => {
    return ctx?.market === 'fi'
  })

  onMounted(() => {
    setTimeout(() => {
      height.value = `${background.value?.clientHeight}px`
    }, 3000)
  })
</script>

<style lang="scss" scoped>
  .background {
    display: none;
    position: absolute;
    inset: 0;
    z-index: 1;
    overflow: hidden;

    @include mq(medium) {
      display: block;
    }

    :deep(picture) {
      overflow: hidden;
      width: 100%;
      height: 100%;
    }

    :deep(img) {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    :deep(picture).mirror img {
      transform: scaleX(-1);
    }
  }
</style>
