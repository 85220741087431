export default {
  nb: {
    path: 'content.sections',
    merge: true,
    data: {
      contentId: 'u3Un3dcANWwF0rD72RZZM',
      componentRef: 'BrokerNumberedInfoList'
    }
  },
  fi: {
    path: 'content.sections',
    merge: true,
    data: {
      contentId: 'u3Un3dcANWwF0rD72RZZM',
      componentRef: 'BrokerNumberedInfoList'
    }
  }
}
