<template>
  <app>
    <main-page
      v-if="pageData && site"
      :page="pageData"
      :site="site"
      :locale="ctx?.locale"
      :is-mobile="isMobile"
      :num-critical-content-sections="1"
      :custom-components="{
        TrustQuoteSection,
        LeadForm,
        CompanyForm,
        BrokerHero,
        BrokerFaq,
        BrokerCrossSell,
        BrokerNumberedInfoList,
        BrokerProductBlocks,
        BrokerHighlightedPartners
      }"
      :fragment-custom-query="fragmentCustomQuery"
    />
  </app>
</template>

<script setup lang="ts">
  import { type CustomData, MainPage } from '@ocp-zmarta/content-sdk'
  import { getServerState, useSSRContext } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'
  import { computed, defineAsyncComponent } from 'vue'
  import brokerCrossSellData from '../components/custom/broker/cross-sell/data'
  import brokerFaqData from '../components/custom/broker/faq/data'
  import brokerHeroData from '../components/custom/broker/hero/data'
  import brokerHighlightedPartnersData from '../components/custom/broker/highlighted-partners/data'
  import brokerNumberedInfoListData from '../components/custom/broker/numbered-info-list/data'
  import brokerProductBlocksData from '../components/custom/broker/product-blocks/data'
  import companyFormData from '../components/custom/company/company-form-data'
  import leadFormData from '../components/custom/lead/lead-form-data'
  import trustQuoteSectionData from '../components/custom/trust/trust-quote-section-data'
  import app from '../components/layout/app.vue'
  import useFormattedPageData from '../composables/use-formatted-page-data'

  const TrustQuoteSection = defineAsyncComponent(async () => await import('../components/custom/trust/trust-quote-section.vue'))
  const LeadForm = defineAsyncComponent(async () => await import('../components/custom/lead/lead-form.vue'))
  const CompanyForm = defineAsyncComponent(async () => await import('../components/custom/company/company-form.vue'))
  const BrokerHero = defineAsyncComponent(async () => await import('../components/custom/broker/hero/hero.vue'))
  const BrokerFaq = defineAsyncComponent(async () => await import('../components/custom/broker/faq/faq.vue'))
  const BrokerCrossSell = defineAsyncComponent(async () => await import('../components/custom/broker/cross-sell/cross-sell.vue'))
  const BrokerNumberedInfoList = defineAsyncComponent(async () => await import('../components/custom/broker/numbered-info-list/numbered-info-list.vue'))
  const BrokerProductBlocks = defineAsyncComponent(async () => await import('../components/custom/broker/product-blocks/product-blocks.vue'))
  const BrokerHighlightedPartners = defineAsyncComponent(async () => await import('../components/custom/broker/highlighted-partners/highlighted-partners.vue'))

  const ctx = import.meta.env.SSR
    ? useSSRContext()
    : ({
      brand: getServerState('brand'),
      market: getServerState('market'),
      locale: getServerState('locale'),
      device: getServerState('device'),
      userId: getServerState('userId'),
      userIp: getServerState('userIp')
    }) as unknown as NonNullable<ReturnType<typeof useSSRContext>>

  const customData = [] as CustomData[]

  const trustQuoteSectionDataLocale = trustQuoteSectionData[ctx?.locale as keyof typeof trustQuoteSectionData]
  if (trustQuoteSectionDataLocale) customData.push(trustQuoteSectionDataLocale)

  const leadFormDataLocale = leadFormData[ctx?.locale as keyof typeof leadFormData]
  if (leadFormDataLocale) customData.push(leadFormDataLocale)

  const companyFormDataLocale = companyFormData[ctx?.locale as keyof typeof companyFormData]
  if (companyFormDataLocale) customData.push(companyFormDataLocale)

  if (ctx?.brand === 'broker') {
    const brokerHeroDataLocale = brokerHeroData[ctx?.locale as keyof typeof brokerHeroData]
    if (brokerHeroDataLocale) customData.push(brokerHeroDataLocale)

    const brokerFaqDataLocale = brokerFaqData[ctx?.locale as keyof typeof brokerFaqData]
    if (brokerFaqDataLocale) customData.push(brokerFaqDataLocale)

    const brokerCrossSellDataLocale = brokerCrossSellData[ctx?.locale as keyof typeof brokerCrossSellData]
    if (brokerCrossSellDataLocale) customData.push(brokerCrossSellDataLocale)

    const brokerNumberedInfoListDataLocale = brokerNumberedInfoListData[ctx?.locale as keyof typeof brokerNumberedInfoListData]
    if (brokerNumberedInfoListDataLocale) customData.push(brokerNumberedInfoListDataLocale)

    const brokerProductBlocksDataLocale = brokerProductBlocksData[ctx?.locale as keyof typeof brokerProductBlocksData]
    if (brokerProductBlocksDataLocale) customData.push(brokerProductBlocksDataLocale)

    const brokerHighlightedPartnersDataLocale = brokerHighlightedPartnersData[ctx?.locale as keyof typeof brokerHighlightedPartnersData]
    if (brokerHighlightedPartnersDataLocale) customData.push(brokerHighlightedPartnersDataLocale)
  }

  const isMobile = computed(() => ctx?.device === 'mobile')
  const { pageData, site, fragmentCustomQuery } = useFormattedPageData({ ctx, customData })
</script>
