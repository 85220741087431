export default {
  nb: {
    path: 'content.sections',
    merge: true,
    data: {
      contentId: '2UpdmZbAdEqksWhsahh1p9',
      componentRef: 'BrokerProductBlocks'
    }
  },
  fi: {
    path: 'content.sections',
    merge: true,
    data: {
      contentId: '2UpdmZbAdEqksWhsahh1p9',
      componentRef: 'BrokerProductBlocks'
    }
  }
}
