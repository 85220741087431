<template>
  <ul class="product-blocks">
    <li
      v-for="(item, index) in items"
      :key="index"
    >
      <picture>
        <img
          v-if="item.imageSrc"
          :src="item.imageSrc"
          alt=""
        >
      </picture>

      <div class="content">
        <div class="inner">
          <h4
            v-if="item.title"
            key="title"
            v-html="item.title"
          />

          <div
            v-if="item.text"
            key="text"
            v-html="item.text"
          />

          <ul
            v-if="item.list"
            key="list"
          >
            <li
              v-for="(listItem, listIndex) in item.list"
              :key="listIndex"
            >
              {{ listItem }}
            </li>
          </ul>

          <zg-button
            key="button"
            type="button"
          >
            <a
              v-if="item.button"
              :href="item.button.href"
            >
              {{ item.button.text }}
            </a>
          </zg-button>
        </div>
      </div>
    </li>
  </ul>
</template>

<script setup lang="ts">
  import { defineAsyncComponent, type PropType } from 'vue'

  interface Item {
    imageSrc: string
    title: string
    text: string
    list: string[]
    button: {
      href: string
      text: string
    }
  }

  const ZgButton = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then(c => c.ZgButton))

  defineProps({
    items: {
      type: Array as PropType<Item[]>,
      required: false,
      default: null
    }
  })
</script>

<style scoped lang="scss">
  .product-blocks {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;

    & > li {
      display: flex;
      flex-direction: column;
      color: #fff;
      background-color: #24385b;

      &:nth-child(even) {
        @include mq(medium) {
          flex-direction: row;
        }
      }

      &:nth-child(odd) {
        @include mq(medium) {
          flex-direction: row-reverse;
        }
      }
    }

    :deep(.zg-button) {
      color: #000 !important; /* stylelint-disable-line */
    }
  }

  picture,
  .content {
    width: 100%;

    @include mq(medium) {
      width: 50%;
    }
  }

  picture {
    overflow: hidden;
    height: rem(250px);

    @include mq(medium) {
      height: rem(500px);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;

    @include mq(medium) {
      height: rem(500px);
    }

    .inner {
      max-width: rem(460px);
      padding: rhythm();

      :deep(p) {
        margin: 0 0 rhythm();
      }

      ul {
        list-style: disc;
        margin: 0;
      }

      .zg-button {
        margin: rhythm() 0 0;
      }

      a {
        color: inherit;
        border: none;
      }
    }
  }
</style>
